import { FontAwesome5 } from "@expo/vector-icons";
import { View, Text } from "react-native";
import { tafStyles } from "../AppTheme";
import { CloudQuantity, ReportCloud, ReportWind } from "../../app/server/Server";
import { useEffect, useState } from "react";

export type CloundsIndicatorProps = {
    clouds: ReportCloud[] | undefined,
    airportHeight: number | undefined
}

const defaultCeiling: {
    cloud: ReportCloud | null,
    warning: boolean
} = { cloud: null, warning: false }

export default function CloudsIndicator({ clouds, airportHeight }: CloundsIndicatorProps) {
    const [ceiling, setCeiling] = useState(defaultCeiling);


    useEffect(() => {
        const ceiling = determineCeilingFromClouds(clouds);

        const warning = (ceiling?.height && airportHeight && (ceiling.height <= (airportHeight + 1500))) || false;

        setCeiling({ cloud: ceiling, warning: warning });
    }, [clouds, airportHeight])

    // const showWindArrow = (direction: number | undefined, color: string) => {
    //     const rotation = `rotate( ${direction}deg )`;
    //     return (
    //         <View style={[{ transform: rotation }, tafStyles.windIndicatorArrow]}>
    //             <FontAwesome size={30} name="long-arrow-down" style={{ backgroundColor: 'transparent', textAlign: 'center', color: color }} />
    //         </View>
    //     );
    // }

    const determineCeilingFromClouds = (clouds: ReportCloud[] | undefined): ReportCloud | null => {
        if (!clouds)
            return null;

        let c: ReportCloud | null = null;

        clouds.forEach((cloud) => {
            if (
                cloud.height != null &&
                cloud.height < (c?.height || Infinity) &&
                (cloud.quantity === CloudQuantity.OVC || cloud.quantity === CloudQuantity.BKN)
            )
                c = cloud;
        });

        return c;
    }

    const getCeilingWarning = () => {
        if (ceiling?.warning)
            return (
                <>{" "}
                    <FontAwesome5 size={14} name="cloud-download-alt" color="#FDBA74" />
                </>
            )
    }
    const getCeiling = () => {
        if (ceiling) {
            return <Text style={tafStyles.text}>
                Ceiling {ceiling.cloud?.quantity} @ {ceiling.cloud?.height}ft
                {getCeilingWarning()}
            </Text>
        } else {
            return (<Text style={tafStyles.text}>
                No Cloud Ceiling
            </Text>)
        }
    }

    const getClouds = () => {
        const ceilingWarnig = getCeilingWarning();
        return clouds!.sort((a, b) => b.height! - a.height!).map((c) => {
            const isCeiling = (c == ceiling?.cloud) ? "Ceiling " : '';
            const warning = (c == ceiling?.cloud) ? ceilingWarnig : null;
            return (
                <Text style={tafStyles.text} key={`ceiling-${c.height}`}>
                    {isCeiling}
                    {c.quantity} @ {c.height}ft
                    {warning}
                </Text>
            )
        });
    }

    if (clouds) {
        return (
            <View style={[tafStyles.cloundsIndicator, tafStyles.border]} aria-label="Clouds">
                {/* <Text style={{ fontWeight: 'normal', textAlign: 'center' }}>
                    {getCeiling()}
                </Text> */}
                {getClouds()}
            </View>
        )
    } else {
        return (
            <View style={[tafStyles.cloundsIndicator, tafStyles.border]}>
                <Text style={tafStyles.text}>
                    No Cloud Ceiling
                </Text>
            </View>
        )
    }
}
