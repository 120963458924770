import { Text, View, StyleSheet, ScrollView } from 'react-native';
import { defaultStyles, tafStyles } from '../components/AppTheme';
import { Button, SegmentedButtons, Switch, TextInput } from 'react-native-paper';
import React, { useEffect, useRef } from 'react';
import ScreenWrapper from '../components/ScreenWrapper';
import ShowMetar from './components/ShowMetar';
import ShowTaf from './components/ShowTaf';

import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';


const defaultLocations: string[] = [];
const defaultFormat: 'metar' | 'taf' | 'ttaf' = 'metar'

export function HomeScreen() {
    const [displayFormat, setDisplayFormat] = React.useState(defaultFormat);

    const [input, onChangeInput] = React.useState('YSCN YBTH');
    const [locations, onChangeLocations] = React.useState(defaultLocations);

    const scrollView = useRef<ScrollView>(null);

    useEffect(() => {
        onChangeLocations(input.split(' ').filter(s => s && s.trim().length == 4));
        setTimeout(() => {
            scrollView.current?.scrollToEnd();
        }, 1);
    }, [input])

    const onChangeInputText = (value: string) => {
        onChangeInput(value.toUpperCase())
    }

    const getContents = () => {
        if (displayFormat == 'metar')
            return (<ShowMetar locations={locations} />);
        else
            return (<ShowTaf locations={locations} />)
    }

    const insets = useSafeAreaInsets();

    return (
        <ScreenWrapper>
            <View style={[defaultStyles.row, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
                <SegmentedButtons
                    value={displayFormat}
                    onValueChange={(e) => setDisplayFormat(e)}
                    density="medium"
                    style={styles.group}

                    buttons={[
                        {
                            icon: 'weather-cloudy',
                            value: 'metar',
                            label: 'Metar',
                            showSelectedCheck: true,
                            style: styles.button,
                        },
                        {
                            icon: 'calendar-clock',
                            value: 'taf',
                            label: 'TAF',
                            showSelectedCheck: true,
                            style: styles.button,
                        },
                        // {
                        //     icon: 'weather-cloudy-clock',
                        //     value: 'ttaf',
                        //     label: 'Predict',
                        //     showSelectedCheck: true,
                        //     style: styles.button,
                        // },
                    ]}
                />
            </View>

            <View style={defaultStyles.row}>
                <TextInput value={input} onChangeText={(e) => onChangeInputText(e)}
                    style={defaultStyles.textInput_full}
                ></TextInput>
            </View>

            <ScrollView ref={scrollView} style={tafStyles.container} horizontal={true}>
                {getContents()}
            </ScrollView>
        </ScreenWrapper>
    );
}

const styles = StyleSheet.create({
    button: {
        flex: 1
    },
    group: {
        paddingHorizontal: 20,
        justifyContent: 'space-evenly',
        width: '100%'
    },
});
