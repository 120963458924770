import { useEffect, useState } from 'react';
import React from 'react';
import * as m from "moment";

import { ScrollView, View, Text } from 'react-native';
import { Airport, MetarDetail, MetarInfo, Server, TafForecastDetail, TafInfo } from '../server/Server';
import { DefaultColours, tafStyles } from '../../components/AppTheme';
import TimeInfo, { TimeFormatHide } from '../../components/TimeInfo';
import { TimeFormat } from '../../components/AppState';
import VisibilityIndicator from '../../components/helpers/VisibilityIndicator';
import WindIndicator from '../../components/helpers/WindIndicator';
import { ActivityIndicator } from 'react-native-paper';
import CloudsIndicator from '../../components/helpers/CloudsIndicator';

export type MetarInfoProps = {
    code: string
}

const defaultInfo: MetarInfo = {};
const defaultAirport: Airport = { code: '' };
const defaultReport: MetarDetail | undefined = undefined;

export default function MetarInfoDisplay(props: MetarInfoProps) {
    const [info, onChangeInfo] = useState(defaultInfo);

    const [report, onChangeReport] = useState(defaultReport);
    const [airport, onChangeAirport] = useState(defaultAirport);

    const [fullName, onChangeFullName] = useState('');
    const [reportDate, onChangeReportDate] = useState('');

    const [loading, onChangeLoading] = useState(true);
    const [backgroundColor, onChangeBackgroundColor] = useState(DefaultColours.loading.back);

    useEffect(() => {
        setTimeout(() => {
            download(props.code)
        }, 1)
    }, [props.code])

    useEffect(() => {
        let fullName = props.code;
        if (airport?.location?.elevation && airport?.location?.elevation > 0) {
            fullName = `${props.code} @ ${airport?.location?.elevation}ft`;
        }
        onChangeFullName(fullName);
    }, [props.code, airport])

    useEffect(() => {
        if (loading)
            onChangeBackgroundColor(DefaultColours.loading.back);
        else if (report && airport) {
            onChangeBackgroundColor(DefaultColours.loaded.back);
        }
    })

    async function download(code: string) {
        let newInfo = await Server.getMetar(code);
        let airport = await Server.getAirport(code);

        onChangeInfo(newInfo);
        onChangeReport(newInfo?.metar);
        onChangeAirport(airport);

        if (newInfo?.metar) {
            const detail = newInfo.metar;
            // build a date
            const reportDate = m
                .utc(detail.issued).set('date', detail.day).set('hour', detail.hour).set('minute', detail.minute).toISOString();
            onChangeReportDate(reportDate);
        } else {
            onChangeReportDate('');
        }

        onChangeLoading(false)
    }

    const showDetails = () => {
        return (
            <>
                <Text style={tafStyles.text}>
                    <TimeInfo value={reportDate} highlightIfOlderThanMinutes={-1} format='DD MMM @ HH:mm' agoPrefix="Reported" />
                </Text>

                <TimeFormatHide hideOnFormat={TimeFormat.ago}>
                    <Text style={tafStyles.text}>
                        <TimeInfo value={reportDate} highlightIfOlderThanMinutes={-1} format='DD/HH:mm' timeFormat={TimeFormat.ago} agoPrefix="Reported" />
                    </Text>
                </TimeFormatHide>

                <VisibilityIndicator visibility={report?.visibility} />

                <CloudsIndicator clouds={report?.clouds} airportHeight={airport?.location?.elevation} />

                <WindIndicator wind={report?.wind} />

                <Text style={[tafStyles.text, tafStyles.border]}>{report?.remark || ''}</Text>

                <Text style={[tafStyles.clearTaf, tafStyles.border]}>{info.metarRaw}</Text>

                <Text style={[tafStyles.text, tafStyles.border]}>
                    <TimeInfo value={info.refreshedAt} highlightIfOlderThanMinutes={10} timeFormat={TimeFormat.ago} agoPrefix='Updated' />
                </Text>
            </>
        )
    }

    const showInfo = () => {
        return (
            <>
                <Text style={tafStyles.title}>{fullName}</Text>
                <Text style={tafStyles.name}>{info.name}</Text>

                {info?.metar?.issued ? showDetails() :
                    <Text>
                        No Valid Metar Found
                    </Text>
                }
            </>
        )
    }

    const showLoading = () => {
        return (
            <>
                <Text style={tafStyles.title}>{props.code}</Text>
                <ActivityIndicator animating={true} size="large"></ActivityIndicator>
            </>
        )
    }

    return (
        <View style={[{ backgroundColor: backgroundColor }, tafStyles.item]}>
            {(loading) ? showLoading() : showInfo()}
        </View>
    )
}