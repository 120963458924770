export class Server {
    // static readonly _url = 'http://127.0.0.1:5001/airplan-da574/australia-southeast1/{name}';
    static readonly _url = 'https://{name}-y6elvsdlsq-ts.a.run.app/';

    private static url(functionName: string): string {
        return this._url.replace("{name}", functionName);
    }

    static fetchOptions: RequestInit = {
        credentials: 'include',
        mode: 'cors',
    };

    static async getTaf(code: string): Promise<TafInfo> {
        if (code.length != 4)
            return {}

        let result = await fetch(`${Server.url('getForecast')}?code=${code}&_u=${Date.now()}`, Server.fetchOptions);
        let taf = await result.json();
        return taf;
    }

    static async getMetar(code: string): Promise<MetarInfo> {
        if (code.length != 4)
            return {}

        let result = await fetch(`${Server.url('getMetar')}?code=${code}&_u=${Date.now()}`, Server.fetchOptions);
        let value = <MetarInfo>await result.json();
        return value;
    }

    static async getAirport(code: string): Promise<Airport> {
        if (code.length != 4)
            return { code: code }

        let result = await fetch(`${Server.url('getAirportInfo')}?code=${code}&_u=${Date.now()}`, Server.fetchOptions);
        let taf = await result.json();
        return taf;
    }
}

export interface MetarInfo {
    code?: string;
    name?: string;
    refreshedAt?: string;
    metarRaw?: string;
    metar?: MetarDetail;
}

export interface MetarDetail {
    message: string;
    remark?: string;
    station?: string;
    issued: string;

    day: number;
    hour: number;
    minute: number;

    temperature?: number;
    dewPoint?: number;
    auto: boolean;

    visibility?: ReportVisibility;
    wind?: ReportWind;
    altimeter?: ReportAltimeter;

    clouds?: ReportCloud[];
}

export interface TafInfo {
    code?: string;
    name?: string;
    refreshedAt?: string;
    tafRaw?: string;
    forecasts?: TafForecasts;
}
export interface TafForecasts {
    station?: string;
    hour?: number;
    message?: string;
    issued: string;
    start: string;
    end: string;
    forecast?: TafForecastDetail[];
}
export interface TafForecastDetail {
    cavok?: boolean;
    remarks?: [];
    clouds?: ReportCloud[];
    start: string;
    end: string;
    visibility?: ReportVisibility;
    wind?: ReportWind;
    remark?: string;
}


export interface ReportAltimeter {
    unit: string,
    value: string,
}
export interface ReportVisibility {
    unit: string;
    indicator: string;
    value: number
}
export interface ReportWind {
    speed: number;
    direction: string;
    degrees: number;
    gust: number;
    unit: string;
    minVariation?: number;
    maxVariation?: number;
}

export interface ReportCloud {
    height?: number;
    quantity: CloudQuantity;
    type?: CloudType;
    /**
     * Very uncommon. For example "FEW025TCU/CB" seen at airport VOTR.
     *
     * This property can be ignored in almost all cases.
     */
    secondaryType?: CloudType;
}

export enum CloudQuantity {
    /**
     * Sky clear
     */
    SKC = "SKC",
    /**
     * Few
     */
    FEW = "FEW",
    /**
     * Broken
     */
    BKN = "BKN",
    /**
     * Scattered
     */
    SCT = "SCT",
    /**
     * Overcast
     */
    OVC = "OVC",
    /**
     * No significant cloud
     */
    NSC = "NSC"
}
export enum CloudType {
    /**
     * Cumulonimbus
     */
    CB = "CB",
    /**
     * Towering cumulus, cumulus congestus
     */
    TCU = "TCU",
    /**
     * Cirrus
     */
    CI = "CI",
    /**
     * Cirrocumulus
     */
    CC = "CC",
    /**
     * Cirrostratus
     */
    CS = "CS",
    /**
     * Altocumulus
     */
    AC = "AC",
    /**
     * Stratus
     */
    ST = "ST",
    /**
     * Cumulus
     */
    CU = "CU",
    /**
     * Astrostratus
     */
    AS = "AS",
    /**
     * Nimbostratus
     */
    NS = "NS",
    /**
     * Stratocumulus
     */
    SC = "SC"
}


export interface Airport {
    code: string,
    location?: AirportLocation,
    ground?: Frequency,
    ctaf?: Frequency,
    awis?: AwisFrequency
}
export interface Frequency {
    freq?: string
}
export interface AwisFrequency extends Frequency {
    phone: string
}
export interface AirportLocation {
    elevation?: number,
    long?: number,
    lat?: number,
}