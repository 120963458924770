import { ShowLocationsProps } from "./ShowMetar";
import TafInfoDisplay from "./TafInfoDisplay";


export default function ShowTaf(props: ShowLocationsProps) {
	const getLocations = () => {
		return props.locations.map((l, i) =>
			<TafInfoDisplay code={l} key={`taf-${i}`}></TafInfoDisplay>
		);
	}
	return (
		getLocations()
	)
}