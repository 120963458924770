import { View, Text } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { tafStyles } from "../AppTheme";
import { ReportVisibility } from "../../app/server/Server";

export type VisibilityIndicatorProps = {
    visibility: ReportVisibility | undefined;
}

export default function VisibilityIndicator(props: VisibilityIndicatorProps) {
    let arrow = 'question';
    if (props?.visibility?.indicator == 'P')
        arrow = 'chevron-right';
    else
        arrow = 'chevron-left';

    // more than
    if (props?.visibility?.value == 9999 && props?.visibility?.indicator == 'P') {
        return (
            <View style={[tafStyles.text, tafStyles.border]}>
                <Text style={[tafStyles.visibilityIndicator]}>
                    Vis{" "}
                    <FontAwesome size={15} name="chevron-right" style={{ backgroundColor: 'transparent' }} />
                    {" "}
                    10Km</Text>
            </View>
        )
    }
    else {
        return (
            <View style={[tafStyles.text, tafStyles.border]}>
                <Text style={[tafStyles.visibilityIndicator]}>
                    Vis{" "}
                    <FontAwesome size={15} name="chevron-right" style={{ backgroundColor: 'transparent' }} />
                    {" "}
                    {props?.visibility?.value}Km</Text>
            </View>
        )
    }
}
