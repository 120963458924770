import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useFonts } from 'expo-font';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import React from 'react';
import { Text, View } from 'react-native';
import { PaperProvider } from 'react-native-paper';
import { HomeScreen } from './app/Home';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { TabBarIcon } from './components/TabBarIcon';
import { FullTheme } from './components/AppTheme';
import TimeFormatProvider from './components/AppState';
import { SafeAreaView, SafeAreaProvider, initialWindowMetrics, useSafeAreaInsets } from 'react-native-safe-area-context';


function SettingsScreen() {
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text>Settings!</Text>
        </View>
    );
}

const Tab = createBottomTabNavigator();

export default function App() {
    const [loaded, error] = useFonts({
        SpaceMono: require('./assets/SpaceMono-Regular.ttf'),
        ...FontAwesome.font,
    });

    return (
        <SafeAreaProvider>
            <PaperProvider theme={FullTheme}>
                <NavigationContainer theme={FullTheme}>
                    <TimeFormatProvider>
                        <Tab.Navigator initialRouteName='plan'>
                            <Tab.Screen name="Plan" component={HomeScreen}
                                options={{
                                    tabBarIcon: ({ color }) => <TabBarIcon name="map-o" color={color} />,
                                    headerShown: false
                                }}
                            />
                            <Tab.Screen name="Settings" component={SettingsScreen}
                                options={{
                                    tabBarIcon: ({ color }) => <TabBarIcon name="gear" color={color} />
                                }}
                            />
                        </Tab.Navigator>
                    </TimeFormatProvider>
                </NavigationContainer>
            </PaperProvider>
        </SafeAreaProvider>
    );
}
