import React, { Children, useContext } from "react";
import * as m from "moment";

import { useEffect } from "react"
import { Text as DefaultText, TouchableWithoutFeedback } from 'react-native';
import { TimeFormat, TimeFormatContext } from "./AppState";
import { DefaultColours, defaultStyles } from "./AppTheme";


export type LocalTimeProps = {
    /** UTC Time */
    now?: boolean,
    format?: string,
    time?: number,
    value?: string,
    timeFormat?: TimeFormat,
    highlightIfOlderThanMinutes?: number,
    agoPrefix?: string,
};
export type TimeProps = LocalTimeProps & DefaultText['props'];


export default function TimeInfo(props: TimeProps) {
    const { timeFormat, setTimeFormat } = useContext(TimeFormatContext);

    const [displayTime, onChangeDisplayTime] = React.useState("");

    const { style, ...otherProps } = props;
    const usedStyle = style;

    useEffect(() => {
        const time = props.now ? new Date() : getPropsTime();

        const format = props.format || "MMM DD HH:mm";
        var localDisplayTime = '';
        const utcTime = m.utc(time);
        switch (props.timeFormat || timeFormat) {
            case TimeFormat.utc: {
                localDisplayTime = utcTime.format(format) + "Z";
                break;
            }
            case TimeFormat.local: {
                localDisplayTime = utcTime.local().format(format) + "L";
                break;
            }
            case TimeFormat.ago: {
                localDisplayTime = `${props.agoPrefix || ''} ${utcTime.fromNow()}`;
                break;
            }
        }


        onChangeDisplayTime(localDisplayTime);
    }, [props.time, props.value, props.now, props.format, timeFormat])

    const getTextColor = (): string => {
        let color = DefaultColours.updated.recent;

        if (!props.highlightIfOlderThanMinutes || props.highlightIfOlderThanMinutes > 5) {
            const time = props.now ? new Date() : getPropsTime();
            const utcTime = m.utc(time);

            const diff = -m.duration(utcTime.diff(m.now())).asMinutes();
            if (diff > 60) {
                color = DefaultColours.updated.old
            }
            else if (diff > (props.highlightIfOlderThanMinutes || 15)) {
                color = DefaultColours.updated.notLongAgo
            }
        }

        return color;
    }

    function getPropsTime(): number {
        if (props.time && props.time > 0)
            return props.time;
        return m.utc(props.value).toDate().getTime();
    }

    const onClickChangeTimeFormat = () => {
        if (timeFormat == TimeFormat.utc)
            setTimeFormat(TimeFormat.local)
        else if (timeFormat == TimeFormat.local)
            setTimeFormat(TimeFormat.ago)
        else
            setTimeFormat(TimeFormat.utc)
    }

    return (
        <DefaultText style={[{ color: getTextColor() }, usedStyle]} {...otherProps}
            onPress={(e) => onClickChangeTimeFormat()} selectable={props.timeFormat == null}
        >{displayTime}
        </DefaultText>
    )
}

export type TimeFormatHideProps = {
    hideOnFormat: TimeFormat,
    children: string | JSX.Element | JSX.Element[]
};
export function TimeFormatHide(props: TimeFormatHideProps) {
    const { timeFormat } = useContext(TimeFormatContext);



    return (
        (props.hideOnFormat != timeFormat) ?
            props.children
            :
            null
    )
}