import { useEffect, useState } from 'react';
import React from 'react';
import { ScrollView, View, Text } from 'react-native';
import { Airport, Server, TafForecastDetail, TafInfo } from '../server/Server';
import { tafStyles } from '../../components/AppTheme';
import TimeInfo, { TimeFormatHide } from '../../components/TimeInfo';
import { TimeFormat } from '../../components/AppState';
import VisibilityIndicator from '../../components/helpers/VisibilityIndicator';
import WindIndicator from '../../components/helpers/WindIndicator';
import { ActivityIndicator } from 'react-native-paper';
import CloudsIndicator from '../../components/helpers/CloudsIndicator';

export type TafForecastProps = {
    code: string
}

const defaultTafInfo: TafInfo = {};
const defaultAirport: Airport = { code: '' };
const defaultForecast: TafForecastDetail | undefined = undefined;

export default function TafInfoDisplay(props: TafForecastProps) {
    const [taf, onChangeTaf] = useState(defaultTafInfo);

    const [forecast, onChangeForecast] = useState(defaultForecast);
    const [airport, onChangeAirport] = useState(defaultAirport);

    const [fullName, onChangeFullName] = useState('');

    const [loading, onChangeLoading] = useState(true);

    useEffect(() => {
        console.log("Code changed " + props.code)

        setTimeout(() => {
            download(props.code)
        }, 1)
    }, [props.code])

    useEffect(() => {
        let fullName = props.code;
        if (airport?.location?.elevation && airport?.location?.elevation > 0) {
            fullName = `${props.code} @ ${airport?.location?.elevation}ft`;
        }
        onChangeFullName(fullName);
    }, [props.code, airport])

    async function download(code: string) {
        let newTaf = await Server.getTaf(code);
        let airport = await Server.getAirport(code);

        if (newTaf?.forecasts?.forecast) {
            onChangeForecast(newTaf?.forecasts?.forecast[0])
        }
        onChangeTaf(newTaf);
        onChangeAirport(airport);

        onChangeLoading(false)
    }
    const showTaf = () => {
        return (
            <>
                <Text style={tafStyles.title}>{fullName}</Text>
                <Text style={tafStyles.name}>{taf.name}</Text>

                <Text style={tafStyles.text}>
                    <TimeInfo value={forecast?.start} highlightIfOlderThanMinutes={-1} format='MMM DD @ HH:mm' />
                    {" ... "}
                    <TimeInfo value={forecast?.end} highlightIfOlderThanMinutes={-1} format='HH:mm' />
                </Text>

                <TimeFormatHide hideOnFormat={TimeFormat.ago}>
                    <Text style={tafStyles.text}>
                        <TimeInfo value={forecast?.start} highlightIfOlderThanMinutes={-1} format='DD/HH:mm' timeFormat={TimeFormat.ago} />
                        {" ... "}
                        <TimeInfo value={forecast?.end} highlightIfOlderThanMinutes={-1} format='DD/HH:mm' timeFormat={TimeFormat.ago} />
                    </Text>
                </TimeFormatHide>

                <VisibilityIndicator visibility={forecast?.visibility} />

                <CloudsIndicator clouds={forecast?.clouds} airportHeight={airport?.location?.elevation} />

                <WindIndicator wind={forecast?.wind} />

                <Text style={[tafStyles.text, tafStyles.border]}>{forecast?.remark || ''}</Text>

                <Text style={[tafStyles.clearTaf, tafStyles.border]}>{taf.tafRaw}</Text>

                <Text style={[tafStyles.text, tafStyles.border]}>
                    <TimeInfo value={taf.refreshedAt} highlightIfOlderThanMinutes={10} timeFormat={TimeFormat.ago} agoPrefix='Updated' />
                </Text>
            </>
        )
    }

    const showLoading = () => {
        return (
            <>
                <Text style={tafStyles.title}>{props.code}</Text>
                <ActivityIndicator animating={true} size="large"></ActivityIndicator>
            </>
        )
    }

    return (
        <View style={tafStyles.item}>
            {(loading) ? showLoading() : showTaf()}
        </View>
    )
}