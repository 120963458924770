import { StyleSheet } from 'react-native';

import { DefaultTheme as NativeDefaultTheme } from '@react-navigation/native';
import {
    DefaultTheme,
    MD3DarkTheme,
    useTheme,
} from 'react-native-paper';

const theme = {
    ...DefaultTheme, // or MD2DarkTheme
    ...NativeDefaultTheme,

    colors: {
        ...DefaultTheme.colors,
        ...NativeDefaultTheme.colors
    },
};


export type AppTheme = typeof theme;
export const FullTheme = theme;

export const useAppTheme = () => useTheme<AppTheme>();

export const defaultStyles = StyleSheet.create({
    button: {
        margin: 4,
        color: theme.colors.primary
    },

    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 8,
        paddingHorizontal: 16,
    },

    textInput_full: {
        width: '100%'
    },

    // tafContainer: {
    //     display: 'flex',
    //     textAlign: 'center',
    //     padding: 5
    // },
})



export const tafStyles = StyleSheet.create({
    container: {
        display: 'flex',
        overflowX: 'auto',
        textAlign: 'center',
        paddingRight: 5
    },
    item: {
        width: 190,
        alignItems: 'center',
        border: 'solid 1px #0d2136',
        borderRadius: 3,
        marginLeft: 5,
        flex: 1,
        flexDirection: 'column'
    },

    title: {
        fontSize: 20,
        fontWeight: 'bold',
        height: 40,
        width: '100%',
        textAlign: 'center'
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold',
        height: 30,
        paddingTop: 4,
        width: '100%',
        textAlign: 'center'
    },
    text: {
        fontSize: 14,
        height: 28,
        paddingTop: 3,
        width: '100%',
        textAlign: 'center'
    },
    clearTaf: {
        fontSize: 8,
        fontFamily: 'SpaceMono',
        fontWeight: 'normal',
        height: 100,
        textAlign: 'left',
        overflow: 'scroll'
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
    textInput: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
    },

    border: {
        borderTopColor: '#BFDBFE',
        borderTopWidth: 1
    },

    visibilityIndicator: {
        textAlign: 'center'
    },
    cloundsIndicator: {
        backgroundColor: '#EEF2FF',
        width: '100%',
        height: 28 * 3,
    },

    windIndicator: {
        height: 90,
        backgroundColor: '#FAF5FF',
        width: '100%',
        textAlign: 'center',
        fontSize: 13,
        position: 'relative'
    },
    windIndicatorWinds: {
        position: 'relative',
        height: 35,
    },
    windIndicatorArrow: {
        position: 'absolute',
        backgroundColor: 'transparent',
        top: 0,
        width: '100%'
    },

    windSpeed: {
        height: 30,
        width: '100%',
        textAlign: 'center',
        fontSize: 15,
        marginTop: 4,
    }
});

export const DefaultColours = {
    loading: {
        back: '#FDE68A'
    },
    loaded: {
        back: '#ECFDF5'
    },
    updated: {
        recent: '#111827',
        notLongAgo: '#FDBA74',
        old: '#B91C1C'
    }
}