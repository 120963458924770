import { FontAwesome } from "@expo/vector-icons";
import { View, Text } from "react-native";
import { tafStyles } from "../AppTheme";
import { ReportWind } from "../../app/server/Server";

export type WindIndicatorProps = {
    wind: ReportWind | undefined
}

export default function WindIndicator({ wind }: WindIndicatorProps) {

    const showWindArrow = (direction: number | string, color: string) => {
        const rotation = `rotate( ${direction}deg )`;
        return (
            <View style={[{ transform: rotation }, tafStyles.windIndicatorArrow]}>
                <FontAwesome size={30} name="long-arrow-down" style={{ backgroundColor: 'transparent', textAlign: 'center', color: color }} />
            </View>
        );
    }

    const getGusts = () => {
        if (wind?.gust && wind.gust > 0) {
            return (<Text style={{ color: 'red' }}>
                {" "}
                G {wind.gust}{wind.speed}
            </Text>);
        }
    }
    const getDirection = () => {
        if (wind?.degrees) {
            let textDirection = `${wind.degrees}°`;

            if (wind.minVariation && wind.maxVariation) {
                textDirection = `${wind.degrees}° Var ${wind.minVariation}°...${wind.maxVariation}°`;
            }

            return (
                <>
                    <View style={tafStyles.windIndicatorWinds} aria-label="Winds">
                        {showWindArrow(wind.degrees, 'black')}
                    </View>

                    <Text style={{ fontWeight: 'normal', textAlign: 'center' }}>
                        {textDirection}
                    </Text>
                </>
            )
        } else {
            return (
                <View style={tafStyles.windIndicatorWinds} aria-label="Winds">
                    <FontAwesome size={30} name="circle-o" style={{ backgroundColor: 'transparent', textAlign: 'center' }} />
                </View>
            )
        }
    }

    if (wind) {
        return (
            <View style={[tafStyles.windIndicator, tafStyles.border]} aria-label="Wind Indicator">
                <Text style={tafStyles.windSpeed}>
                    Wind {wind.direction} {wind?.speed}{wind?.unit}
                    {getGusts()}
                </Text>

                {getDirection()}
            </View>
        )
    }
}
