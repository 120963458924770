import MetarInfoDisplay from "./MetarInfoDisplay";

export type ShowLocationsProps = {
	locations: string[]
};

export default function ShowMetar(props: ShowLocationsProps) {
	const getLocations = () => {
		return props.locations.map((l, i) =>
			<MetarInfoDisplay code={l} key={`metar-${i}`}></MetarInfoDisplay>
		);
	}
	return (
		getLocations()
	)
}