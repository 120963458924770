import { createContext, useMemo, useState } from "react";

export enum TimeFormat { 'utc', 'local', 'ago' }

export const TimeFormatContext = createContext({
    timeFormat: TimeFormat.ago,
    setTimeFormat: (value: TimeFormat): void => { }
})

type TimeFormatProviderProps = {
    children: string | JSX.Element | JSX.Element[]
}

export default function TimeFormatProvider(props: TimeFormatProviderProps) {
    const [timeFormat, setTimeFormat] = useState(TimeFormat.ago);
    const value = useMemo(() => ({ timeFormat, setTimeFormat }), [timeFormat]);

    return (
        <TimeFormatContext.Provider value={value}>
            {props.children}
        </TimeFormatContext.Provider>
    );
}
